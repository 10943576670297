<script>
  import appConfig from "@/app.config";

  export default {
    page: {
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    },
    data() {
      return {
        surveys: [],
        Users: {},
        page: 1,
        limit: 20,
        tot_pages: 0,
        searchUsers: null,
        SearchMode: false,
        emps: [],
        new_roles: [],
        newRoles: [{}],
        survey_team: [{}],
        survey_areas: [{}],
        marketingAreas: [],
        marketingDistricts:[],
        marketingTeam: [],
        branches: [],
        user_info: {},
        new_pass: "",
        users_switch: "",
        user_status: 0,
        data: {},
        roles: [],
        user: {}

        // appprices:[]
      };
    },
    methods: {
      getMarketingAreas() {
        this.http.get("marketing-areas",null,null,true).then((res) => {
          this.marketingAreas = res.data;
        });
      },
      getMarketingDistricts(){
this.http.get("marketing-districts",null,null,true).then((res) => {
          this.marketingDistricts = res.data;
        });
      },
      getMarketingTeam() {
        this.http.get("marketing-team-members", null, null, true).then((res) => {
          this.marketingTeam = res.data;
        });
      },
      navigate(survey) {
        this.$router.push({name :'marketing_survey_details', params:{ id : survey?.id }})
      },
      addSurvey() {
        this.data.survey_team = this.survey_team;
        this.data.survey_areas = this.survey_areas;
        console.log({ data: this.data });
        this.http.post("marketing-surveys", this.data, null, true).then((res) => {
          if (res.status) {
            this.data = {};
            this.survey_team = [{}];
            this.survey_areas = [{}];
            this.get(this.page);
            this.popup.alert({ title: '', msg: 'popups.success' })
             document.querySelector('#addModal .close-modal-btn').click()

          }
        });
      },
      search() {
        this.SearchMode = true;
        console.log(this.searchUsers);
        this.tot_pages = 0;
        this.http
          .post("marketing-surveys/search", {
            limit: this.limit,
            page: this.page,
            search: this.searchUsers,
            col: "name",
          },null,true)
          .then((res) => {
            this.surveys = res.data;
          });
      },
      cancelSearchMode() {
        this.searchUsers = "";
        this.SearchMode = false;
        this.get(this.page);
      },
  
      deleteSurvey(survey) {
        var data = {
          title: "popups.deleteuser",
          msg: "popups.deletemsg",
          buttons: [
            {
              text: "Delete",
              closer: true,
              color: "danger",
            },
            {
              text: "Cancel",
              closer: true,
              color: "light",
              handler: () => {
                console.log("Like Clicked");
              },
            },
          ],
        };
        this.popup.confirm(data).then(() => {
          this.http
            .delete("marketing-surveys",  survey.id )
            .then((res) => {
              if(res.status)
              this.get(this.page);
            });
        });
      },
    
      deleteAddedSurveyArea(area) {
        this.survey_areas = this.survey_areas.filter((i) => i != area);
      },
      deleteAddedSurveyTeam(member) {
        this.survey_team = this.survey_team.filter((i) => i != member);
      },
      get(page) {
        console.log(this.userstable);
        this.http
          .post("marketing-surveys/pagination", {
            limit: this.limit,
            page: page,
          },null,true)
          .then((res) => {
            this.page = page;
            this.tot_pages = Math.ceil(res.tot / this.limit);
            this.surveys = res.data;
          });
      },
      getSwitch(survey, status) {
        console.log(status);
        this.http
          .post("marketing-surveys/update-status", {
            status: status,
            user_id: survey.id,
          },null,true)
          .then(() => {
            this.get(this.page);
          });
      },
      exportExcel(survey){
      this.http.download("marketing-surveys/export",`${survey.name}.xlsx`,{
        id:survey.id
      })
      }
    },
    created() {
      // console.log("steo 164 :", JSON.parse(localStorage?.user))
      this.user=JSON.parse(localStorage?.user)
      this.get(1);
      this.getMarketingAreas();
      this.getMarketingDistricts()
      this.getMarketingTeam();
    },
  };
</script>

<template>
  <div class="row">
    <div>
      <div class="row align-items-center">

        <div class="col-6">

          <div
            class="search-box chat-search-box d-flex align-items-center"
            style="width: 250px; margin-inline-end: auto"
          >
            <div class="align-items-center d-flex position-relative mx-3">
              <input
                @keyup="search()"
                type="text"
                class="form-control fa-lg text-light"
                :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
                :placeholder="$t('users.searchplaceholder')"
                v-model="searchUsers"
                style="background-color: #2a3042 !important"
              />
              <i class="bx bx-search-alt search-icon text-light"></i>
              <button
                @click="cancelSearchMode()"
                v-if="SearchMode"
                class="btn-close text-light"
                style="position: absolute; top: 12px; left: 250px"
              ></button>
            </div>

          </div>
        </div>
                <div class="col-6">
          <button
              type="button"
              class="btn btn-light float-start"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
              :disabled="user.roles.some(role=> role?.role?.code =='marketing_survey_manager_no_edit')"
          >
              <span
                  class="bx bxs-plus-circle float-end fa-2x"
                  style="color: #2a3042 !important"
              ></span>
          </button>
        </div>

      </div>
      <!-- end row -->

      <hr class="mb-4" />

      <div class="row">
        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
          <thead>
            <tr
              class="text-light text-center"
              style="background-color: #2a3042 !important"
            >
              <th scope="col">{{ $t("marketing.survey_list.table.id") }}</th>
              <th scope="col">
                {{ $t("marketing.survey_list.table.name") }}
              </th>
              <th scope="col">{{ $t("users.userstable.created") }}</th>
              <th scope="col">{{ $t("users.userstable.updated") }}</th>
              <!-- <th scope="col">
                {{ $t("marketing.survey_list.table.details") }}
              </th> -->
              <th scope="col">{{ $t("users.userstable.operations") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center" v-for="(survey, index) in surveys" :key="survey">
              <td>{{ index + 1 }}</td>
              <td>{{ survey.name }}</td>
              <td>{{ survey.created.split("T")[0] }}</td>
              <td>{{ survey.updated.split("T")[0] }}</td>
              
              <td class="d-flex justify-content-center">
                <button
                                :title="$t('marketing.survey_list.table.export')"
                  @click="exportExcel(survey)"
                class="btn btn-success btn-sm me-2"
                >
                  <i class="bx bx-spreadsheet"></i>

                </button>
                <button
                :title="$t('marketing.survey_list.table.details')"
                  @click="navigate(survey)"
                  class="btn btn-primary btn-sm me-2"
                >
                  <i class="bx bx-detail"></i>
                </button>
                <button
                  @click="getSwitch(survey, 1)"
                  v-if="survey.status == 0"
                  class="btn btn-primary btn-sm me-2"
                >
                  {{ $t("popups.enable") }}
                </button>
                <button
                  type="button"
                  @click="getSwitch(survey, 0)"
                  v-if="survey.status == 1"
                  class="btn btn-secondary btn-sm me-2"
                >
                  {{ $t("popups.disable") }}
                </button>
                <button
                  class="btn btn-danger btn-sm me-2"
                  :class="{}"
                  
                  @click="deleteSurvey(survey)"
                                :disabled="user.roles.some(role=> role?.role?.code =='marketing_survey_manager_no_edit')"

                  >{{ $t("popups.delete") }}</button
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!--Start Add Modal-->
  <div
    class="modal fade"
    id="addModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="addSurvey()">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("popups.add_marketing_survey") }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style="margin: 0"
            ></button>
          </div>

          <div class="modal-body">
            <label for="usename">{{ $t("popups.marketing_survey_name") }}</label>
            <input
              v-model="data.name"
              id="name"
              required
              class="form-control"
              type="text"
              :placeholder="$t('popups.name')"
            />

            <!-- add Survey Areas start -->
            <div class="row d-flex align-items-center">
              <div class="col">
                <label for="user_role">{{ $t("popups.add_area") }}</label>
              </div>
              <div class="col d-flex justify-content-end">
                <button
                  type="button"
                  @click="survey_areas.push({})"
                  class="btn float-end"
                >
                  <span
                    class="bx bxs-plus-circle float-end fa-2x"
                    style="color: #2a3042 !important"
                  ></span>
                </button>
              </div>
            </div>
            <div v-for="area in survey_areas" :key="area">
              <div
                class="border border-2 px-4 pt-5 pb-4 my-4"
                style="border-radius: 0.25rem; position: relative"
              >
                <!-- close icon -->
                <div
                  class="d-none d-lg-inline-block ms-1"
                  style="position: absolute; left: -0.5rem; top: -0.8rem"
                  @click="deleteAddedSurveyArea(area)"
                  v-if="survey_areas.length > 1"
                >
                  <button type="button" class="btn header-item noti-icon">
                    <i class="bx bx-trash text-danger fa-2xl"></i>
                  </button>
                </div>
                <div class="mb-3">
                  <label for="" class="form-label">{{$t('marketing.districts')}}</label>
                  <select
                  @change="delete area.id"
                    v-model="area.marketing_district_id"
                    class="form-select form-select"
                    name=""
                    id=""
                  >
<option v-for="a in marketingDistricts" :key="a.id" :value="a.id">
                    {{ a.name }}
                  </option>                    
                  </select>
                </div>
                  <div class="mb-3">
                 <label for="" class="form-label">{{$t('marketing.team_member.tabs.areas')}}</label>

                <select v-model="area.id" class="form-select mb-2" id="area">
                  <option v-for="a in marketingAreas.filter(aa => aa.marketing_district_id == area.marketing_district_id)" :key="a.id" :value="a.id">
                    {{ a.name }}
                  </option>
                </select>
                  </div>
              </div>
            </div>
            <!-- add Survey Areas end -->

            <!-- add team member start -->
            <div class="row d-flex align-items-center">
              <div class="col">
                <label for="user_role">{{ $t("popups.add_team") }}</label>
              </div>
              <div class="col d-flex justify-content-end">
                <button
                  type="button"
                  @click="survey_team.push({})"
                  class="btn float-end"
                >
                  <span
                    class="bx bxs-plus-circle float-end fa-2x"
                    style="color: #2a3042 !important"
                  ></span>
                </button>
              </div>
            </div>
            <div v-for="member in survey_team" :key="member">
              <div
                class="border border-2 px-4 pt-5 pb-4 my-4"
                style="border-radius: 0.25rem; position: relative"
              >
                <!-- close icon -->
                <div
                  class="d-none d-lg-inline-block ms-1"
                  style="position: absolute; left: -0.5rem; top: -0.8rem"
                  @click="deleteAddedSurveyTeam(member)"
                  v-if="survey_team.length > 1"
                >
                  <button type="button" class="btn header-item noti-icon">
                    <i class="bx bx-window-close text-danger fa-2xl"></i>
                  </button>
                </div>
                <select v-model="member.id" class="form-select mb-2" id="area">
                  <option v-for="m in marketingTeam" :key="m.id" :value="m.id">
                    {{ m.name }}
                  </option>
                </select>
              </div>
            </div>
            <!-- add team member end -->
          </div>
          <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <button type="submit" class="btn btn-primary">
              {{ $t("popups.add") }}
            </button>
            <button
              data-bs-dismiss="modal"
              type="button"
              class="btn btn-danger close-modal-btn"
            >
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--End Add Modal-->
</template>
